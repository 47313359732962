import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Text from "../../../components/Text";
import Column from "../../../components/Column";
import Table from "../../../components/Table";
import { useConnectionsStore } from "../../../stores/connections/useConnectionsStore";
import { calculateColumnWidth } from "../../../utils";
import DropdownMenu from "../../../components/dropdown/DropdownMenu";
import DropdownItem from "../../../components/dropdown/DropdownItem";
import { useLocation, useNavigate } from "react-router-dom";
import { styles } from "../../../styles/themes/style";
import ApplyRemoveTagsSelect, { IEditableTag } from "../../../fragments/ApplyRemoveTagsSelect";
import Switch from "../../../components/Switch";
import { useAppActions } from "../../../stores/application/useAppActions";
import { useCSVImportRequest } from "../../../services/apis/csv/useCSVimportRequest";
import BottomBarCSV from "../components/BottomBarCSV";
import { AppRoutes, SidebarTypes } from "../../../interfaces/store/appStore";
import { useAppHandlers } from "../../../services/actions/app/useAppHandlers";
import { Dropdown } from "react-bootstrap";
import Row from "../../../components/Row";
import FormControl from "../../../components/FormControl";
import { useTagsHandlers } from "../../../services/actions/tags/useTagsHandlers";
import useMediaQuery from "../../../components/useMediaQuery";

interface IMappingCSVProps { }

const MappingCSV: FunctionComponent<IMappingCSVProps> = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const { csvData } = location.state || {}
    const tableRef = useRef<HTMLDivElement>(null)
    const isSmall = useMediaQuery("(max-width: 768px)")

    const { openSidebar } = useAppHandlers()
    const { setToast } = useAppActions()
    const { importMapping } = useCSVImportRequest()
    const { createTagHandler } = useTagsHandlers();

    const [selectedOptions, setSelectedOptions] = useState<any>({})
    const [value, setValue] = useState<IEditableTag[]>([])
    const [addContacts, setAddContacts] = useState<boolean>(false)

    const [columns, setColumns] = useState([
        { header: 'Column', key: ['column'], width: '150px', show: true, showSmall: true },
        { header: 'Preview', key: ['preview'], show: true, showSmall: true },
        { header: 'Mapping', key: ['actions'], width: '150px', show: true, showSmall: true }
    ])

    const options = [
        { value: 'nothingSelected', label: 'Nothing Selected', tooltip: null },
        { value: 'email', label: 'Email Address', tooltip: 'e.g., john.doe@leaddelta.com' },
        { value: 'firstName', label: 'First Name', tooltip: 'e.g., John' },
        { value: 'lastName', label: 'Last Name', tooltip: 'e.g., Doe' },
        { value: 'location', label: 'Location', tooltip: 'e.g., New York' },
        { value: 'country', label: 'Country', tooltip: 'e.g., USA' },
        { value: 'company', label: 'Company', tooltip: 'e.g., ABC Inc.' },
        { value: 'followerCount', label: 'Follower Count', tooltip: 'e.g., 5000' },
        { value: 'headline', label: 'Headline', tooltip: 'e.g., Software Developer' },
        { value: 'industry', label: 'Industry', tooltip: 'e.g., Technology' },
        { value: 'linkedinUrl', label: 'LinkedIn', tooltip: 'e.g.,http://www.linkedin.com/in/doe' },
        { value: 'jobTitle', label: 'Job Title', tooltip: 'e.g., Senior Manager' },
        { value: 'phoneNumber', label: 'Phone Number', tooltip: 'e.g., +1 (123) 456-7890' },
        { value: 'birthDate', label: 'Birthday', tooltip: 'e.g., MM/DD/YYYY' },
        { value: 'languages', label: 'Languages', tooltip: 'e.g., English, Spanish' }

    ]

    useEffect(() => {
        if(!csvData) navigate(AppRoutes.CSV_IMPORT)
    }, [])
    
    useEffect(() => {
        const offsetWidth = tableRef.current?.offsetWidth
        const adjustedColumns = calculateColumnWidth(offsetWidth, columns, isSmall)
        setColumns(adjustedColumns)
    }, [isSmall])

    const handleSelectChange = (selectedOption: any, columnKey: any) => {
        console.log(selectedOption.target.attributes['data-id'].value, columnKey)
        if(selectedOption != null) {
            setSelectedOptions((prevSelectedOptions: any) => ({
                ...prevSelectedOptions,
                [columnKey]: selectedOption.target.attributes['data-id'].value === 'nothingSelected' ? null : selectedOption.target.attributes['data-id'].value
            })
        )}
    }

    const handleUpdateCsv = async () => {
        try {
            let hasEmailMapping = false;
            let hasLinkedinUrl = false;
            
            const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/; // Regular expression for email validation

            const mappingRequest = Object.keys(csvData?.columns).reduce((acc, columnKey) => {
                const selectedOption = selectedOptions[columnKey];
                let mappingValue = selectedOption ? selectedOption : null;

                if (mappingValue && Object.values(acc).includes(mappingValue)) {
                    throw new Error(`Duplicate column found: ${mappingValue}.`)
                }

                if (mappingValue === 'email') {
                    hasEmailMapping = true;

                    const emailValue = csvData.firstRow[columnKey];

                    if (!emailRegex.test(emailValue)) {
                        throw new Error(`Invalid email format.`);
                    }
                }

                if (mappingValue === 'linkedinUrl') hasLinkedinUrl = true;

                return { ...acc, [columnKey]: mappingValue };
            }, {});

            if(!hasEmailMapping && !hasLinkedinUrl) {
                throw new Error('LinkedIn url or email mapping is mandatory.')
            }

            // const tagList = selectedTags.map(item => item._id);

            navigate(AppRoutes.SETTINGS_DATA_MANAGEMENT)
            const updatedTags = await processValues(value)
            await importMapping({id: csvData.id, body: { mapping: mappingRequest , tags: updatedTags.map(item => item._id) ?? [], updateExistingConnections: !addContacts}})
            setToast({ type:'success', message: 'Import data mapping is successful. Your connections will be updated once the process is finished.'})

        } catch (error: any) {
            setToast({ type:'error', message: error?.message ?? "There was a problem performing this action!" })
        // Handle error
        }
      }

    const processValues = async (values: IEditableTag[]) => {
    for (let i = 0; i < values.length; i++) {
        const item = values[i];
        if (item && !item._id) {
            const newItem = await createTagHandler(item as any);
            values[i] = newItem;
        }
    }
        return values;
    }


    return (
        <Container alignItems gap="20px">
            <Column alignItems gap="5px">
                <Text $heading3 $bold>Map CSV Table</Text>
                <Text $lighter>Map columns from the left with the columns from the uploaded CSV on the right to import contacts.</Text>
            </Column>
           <Row gap="20px">
            <StyledColumnTag gap="24px">
                <FormControl>
                    <Text $label>Apply tag</Text>
                    <ApplyRemoveTagsSelect
                        prefilledValue={[]}
                        onChangeHandler={(value: IEditableTag[]) => setValue([...value])}
                    />
                </FormControl>
                <FormControl>
                    <Text $label>Import preferences</Text>
                    <Row gap="10px" alignItems>
                        <Switch prefilledValue={addContacts} onClickHandler={() => setAddContacts(!addContacts)} />
                        <Text>Add only connections that do not already exist</Text>
                    </Row>
                    <Row gap="10px" alignItems>
                        <Switch prefilledValue={!addContacts} onClickHandler={() => setAddContacts(!addContacts)} />
                        <Text>Overwrite the existing connections with the new data</Text>
                    </Row>
                </FormControl>
            </StyledColumnTag>
            <StyledColumnTable gap="10px">
                <StyledColumnHeader>
                    {columns.map((column: any, index: number) => (<div key={index} style={{width: column.width}}><Text $lighter $heading6>{column.header}</Text></div>))}
                </StyledColumnHeader>
                <TableWrapper>
                    {csvData ? <Table
                        ref={tableRef}
                        $hideColumns
                        columns={columns}
                        data={Object.keys(csvData?.columns).map((columnKey: any) => ({
                            column: (
                                <Text $black $bold $ellipsis="0">{csvData?.columns[columnKey]}</Text>
                            ),
                            preview: (
                                <Text $ellipsis='3'>{csvData?.firstRow[columnKey]}</Text>
                            ),
                            actions: (
                                <StyledDropdownMenu title={<Text $lighter>{options.find((option) => option?.value === selectedOptions[columnKey])?.label ?? 'Nothing Selected'}</Text>}>
                                    {options.map((column: any) => (
                                        <DropdownItem data-id={column.value} onClickHandler={(selectedOption: any) => handleSelectChange(selectedOption, columnKey)}>{column.label}</DropdownItem>
                                    ))}
                                    {/* <AddNewField onClick={() => openSidebar(SidebarTypes.CUSTOM_FIELD_EDIT_CREATE)}><Text $black $bold>+ Create New</Text></AddNewField>  */}
                                </StyledDropdownMenu>
                            )
                        }))}              
                    /> : null}
                </TableWrapper>
                <BottomBarCSV onClickHandler={handleUpdateCsv} />
            </StyledColumnTable>
           </Row>
        </Container>
    )
}
const Container = styled(Column)`
    width: 100%;
    height: calc(100% - 70px);
    padding: 40px 0 0 0;
    overflow: auto;
`
const TableWrapper = styled.div`
    width: 620px;
`
const StyledColumnHeader = styled(Row)`
    width: 620px;
    padding: 0 15px 0 15px;
`
const StyledDropdownMenu = styled(DropdownMenu)`
    margin-left: auto;

    .actions-dropdown + div {
        height: 250px;  
    }
`
const StyledColumnBody = styled(Column)`
    flex-direction: row;
`
const StyledColumnTable = styled(Column)``

const StyledColumnTag = styled(Column)`
    width: 350px;
    height: 292px;
    border: 1px solid ${styles.colors.black200};
    padding: 24px 16px;
`
const StyledToogleColumn = styled(Column)`
    flex-direction: row
`
const AddNewField = styled.div`
    height: 50px;
    display: flex;
    align-items: center;
    position: sticky;
    bottom: -10px;
    background-color: ${styles.colors.white};
    border-top: 1px solid ${styles.colors.black200};
    padding: 10px;
    z-index: 1000;
`
export default MappingCSV