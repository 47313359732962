import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import _ from 'lodash';
import { useConnectionsStore } from '../stores/connections/useConnectionsStore'
import Select from '../components/select/Select';
import AppTag from './AppTag';
import styled from 'styled-components';
import { IConnection } from '../interfaces/models/connection';

interface IBulkRemoveTagsSelectProps {
    onChangeHandler?: (e: any) => void,
    applicableConnections?: IConnection[]
}

export interface IEditableTag {
    _id?: string,
    color?: string,
    title?: string,
    value?: string,
    label?: string,
    private?: boolean,
    isForbidden?: boolean,
    new?: boolean,
}

const BulkRemoveTagsSelect: FunctionComponent<IBulkRemoveTagsSelectProps> = ({ onChangeHandler, applicableConnections }) => {

    const { store: {connectionFilterOptions: { tagFilterOptions }} } = useConnectionsStore();
    const [selectOptions, setSelectOptions] = useState<IEditableTag[]>([]);
    const [selectedTags, setSelectedTags] = useState<IEditableTag[]>([])


    useEffect(() => {
        const options = tagFilterOptions?.filter((tag: IEditableTag) => !tag.isForbidden).map((option: any) => ({...option, label: option.title, value: option._id}));
        let newOptions: IEditableTag[] = []
        if(applicableConnections) applicableConnections.forEach(connection => {
            newOptions = [...newOptions, ...connection?.tags]
        })
        setSelectOptions(newOptions?.length ? options.filter((option: any) => newOptions.some(tag => tag._id === option._id)) : options);
    }, [tagFilterOptions]);


    const onRemove = useCallback((tag: IEditableTag) => {
        setSelectedTags(selectedTags.filter((selectedTag: IEditableTag) => selectedTag._id !== tag._id))
    }, [selectedTags])


    const onChange = useCallback((tags: IEditableTag[]) => {
        setSelectedTags([...tags])
        onChangeHandler && onChangeHandler([...tags])
    }, [selectedTags])

    return <Select 
        isMulti
        isSearchable
        menuIsOpen={true}
        placeholder = {'Type tag name...'}
        onChange = {(e: any) => onChange(e)}
        options={selectOptions}
        value={selectedTags}
        components={{
            DropdownIndicator: null,
            ClearIndicator: () => null,
            Option: (itemProps: any) => {
                return (
                    <StyledOption {...itemProps.innerProps}>
                        <AppTag 
                            tag={{...itemProps.data, title: itemProps.data.label}}
                            />
                    </StyledOption>
                )
            }, 
            MultiValue: (itemProps: any) => {
                return (
                    <AppTag 
                        tag={{...itemProps.data, title: itemProps.data.label}} 
                        onDestructiveClickHandler={() => onRemove(itemProps.data)}
                        />)
            }, 
        }}
    />
}

const StyledOption = styled.div`
    padding: 6px 20px;
`



export default BulkRemoveTagsSelect
