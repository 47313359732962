import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import DropdownMenu from '../../components/dropdown/DropdownMenu'
import SvgFragment from '../SvgFragment'
import DropdownItem from '../../components/dropdown/DropdownItem'
import { useAppHandlers } from '../../services/actions/app/useAppHandlers'
import { AppRoutes, SidebarTypes } from '../../interfaces/store/appStore'
import { IConnection } from '../../interfaces/models/connection'
import Row from '../../components/Row'
import { useConnectionsHandlers } from '../../services/actions/connections/useConnectionsHandlers'
import { useAnalyticsHandlers } from '../../services/actions/analytics/useAnalyticsHandlers'
import { useAuthStore } from '../../stores/auth/useAuthStore'
import { useAuthHandlers } from '../../services/actions/auth/useAuthHandlers'
import { useConnectionsStore } from '../../stores/connections/useConnectionsStore'
import { useAppStore } from '../../stores/application/useAppStore'
import Column from '../../components/Column'
import ActionText from '../../components/ActionText'
import { styles } from '../../styles/themes/style'
import { useExternalHandlers } from '../../services/actions/navigation/useExternalHandlers'
import { useNavigate } from 'react-router-dom'
import useMediaQuery from '../../components/useMediaQuery'
import { isLNFirst } from './../../utils';


interface IBulkConnectionActionsDropdownProps {
    className?: string,
    selectedConnections?: IConnection[],
    selectedAllConnections?: boolean
}

const BulkConnectionActionsDropdown: FunctionComponent<IBulkConnectionActionsDropdownProps> = ({ className, selectedConnections, selectedAllConnections }) => {
    const { openSidebar, closeSidebar, setExportConnectionsModalHandler } = useAppHandlers()
    const { toggleBulkHideConnectionsHandler, exportToCSVHAndler } = useConnectionsHandlers()
    const { getTotalConnectionsOverviewAnalytics } = useAnalyticsHandlers()
    const { store: { user, disconnectedConnectionsCount, isLoggedToLinkedin, workspace } } = useAuthStore()
    const { store: { extensionInstalled, extensionVersion } } = useAppStore()
    const { setDisconnectedConnectionsCountHandler } = useAuthHandlers()
    const { store: { connectionsParameters: {total} }} = useConnectionsStore()
    const [enableLinkedinAction, setEnableLinkedinAction] = useState<boolean>(false)
    const { saveOnboardingData } = useAuthHandlers()
    const { visitChromeStore, openLinkedinWebsite, updateLeadDeltaExtension } = useExternalHandlers()
    const navigate = useNavigate()

    const isScreenMaxWidth1400 = useMediaQuery("(max-width: 1340px)");
    const isExtensionVersionCorrect = extensionVersion === process.env.REACT_APP_VERSION

    useEffect(() => {
        setDisconnectedConnectionsCountHandler(disconnectedConnectionsCount);
     }, [disconnectedConnectionsCount])

    useEffect(() => {
        getTotalDisconnected()
    }, [])

    useEffect(() => {
        if(selectedConnections?.length === 1) {
            setEnableLinkedinAction(isLNFirst(selectedConnections?.[0], user?._id))
        } else {
            setEnableLinkedinAction(true)
            for(const conn of selectedConnections || []) {
                if(!isLNFirst(conn, user?._id)){
                    setEnableLinkedinAction(false);
                    break;
                }
            }
        }
    }, [selectedConnections])

    const getTotalDisconnected = async () => {
        const result = await getTotalConnectionsOverviewAnalytics()
        setDisconnectedConnectionsCountHandler((result.find(item => item.version === 'connections-disconnected-total'))?.value || 0)
    }

    const exportConnections = useCallback(async (includeTags: boolean, includeNotes: boolean, exportAll: boolean) => {
        if(exportAll){
            await exportToCSVHAndler(includeTags, includeNotes)
        }
        else {
            await exportToCSVHAndler(includeTags, includeNotes, selectedConnections)
        }
    }, [selectedConnections]) 

    const bulkConnectionActions = [
        {
            icon: 'crossedLabel',
            title: `Remove Tags`,
            slug: 'removeTags',
            enabled: selectedConnections?.length,
            visible: isScreenMaxWidth1400,
            destructive: true,
            onClickHandler: () => openSidebar(SidebarTypes.REMOVE_TAG, { selectedAllConnections, selectedConnections, onSaveHandler: () => closeSidebar(SidebarTypes.REMOVE_TAG) })
        },
        {
            icon: 'hide',
            title: `Hide`,
            slug: 'hide',
            enabled: selectedConnections?.length,
            visible: isScreenMaxWidth1400,
            destructive: true,
            onClickHandler: () => toggleBulkHideConnectionsHandler(selectedConnections, true)
        },
        {
            icon: 'show',
            title: `Unhide`,
            slug: 'unhide',
            enabled: selectedConnections?.length,
            visible: true,
            showInstallExt: false,
            destructive: false,
            onClickHandler: () => toggleBulkHideConnectionsHandler(selectedConnections, false)
        },
        {
            icon: 'import',
            title: 'Import CSV',
            slug: 'import',
            enabled: !workspace?.isStarter,
            visible: true,
            showInstallExt: workspace?.isStarter,
            destructive: false,
            onClickHandler: () => navigate(AppRoutes.CSV_IMPORT)
        },
        {
            icon: 'export',
            title: 'Export connections',
            slug: 'export',
            enabled: !workspace?.isStarter,
            visible: true,
            showInstallExt: workspace?.isStarter,
            destructive: false,
            onClickHandler: () => {
                setExportConnectionsModalHandler(
                    exportConnections,
                    selectedAllConnections ? total : selectedConnections?.length ?? undefined,
                    selectedAllConnections
                )
            }
        }
    ];

    const installExtensionHandler = useCallback(async () => {
        await Promise.all([
            saveOnboardingData({
                startedExtensionInstallation: true,
            }),
            (async () => visitChromeStore())(),
        ]);
        
    },[]);

    return <DropdownMenu tooltipMessage={'More actions'} className={className} title={<SvgFragmentWrapper disabled={false}>More <SvgFragment type='arrowDown' /></SvgFragmentWrapper>} $hideToggle>
        {bulkConnectionActions.map((connectionAction, index) =>
            connectionAction.visible && <DropdownItem $disabled={!connectionAction.enabled} key={index} $danger={connectionAction.destructive} onClickHandler={(e: any) => connectionAction.onClickHandler && connectionAction.onClickHandler()}>
                { !connectionAction.showInstallExt ? (
                <Row alignItems gap="5px">
                    <SvgFragment type={connectionAction.icon} />{connectionAction.title}
                </Row>
                ) : (
                    <Row alignItems spaceBetween gap="12px">
                        <Column>
                            <Row alignItems gap="5px">
                                <SvgFragment type={connectionAction.icon}/>
                                {connectionAction.title}
                            </Row>
                        </Column>
                        <Column>
                            <Row alignItems gap="4px">
                                <StyledActionText icon='lock2'
                                    onClickHandler={(e: any) => {
                                        e.stopPropagation()
                                        if (workspace.isStarter) return window.open(`${window.location.origin}${AppRoutes.BILLING}`, '_blank');;
                                        if (!extensionInstalled) installExtensionHandler()
                                        if (!isExtensionVersionCorrect) updateLeadDeltaExtension()
                                        if (!isLoggedToLinkedin) openLinkedinWebsite()
                                    }}
                                >
                                    {workspace.isStarter && (connectionAction?.slug === 'import' || connectionAction?.slug === 'export') ? 'Upgrade'
                                        : !extensionInstalled ? 'Install Extension' 
                                        : !isExtensionVersionCorrect ? 'Update extension' 
                                        : !isLoggedToLinkedin ? 'Login to Linkedin'
                                        : ''
                                    }
                                </StyledActionText>
                            </Row>
                        </Column>
                    </Row>
                )
            }
            </DropdownItem>)}
    </DropdownMenu>
}

const SvgFragmentWrapper = styled.div<{ disabled: boolean }>`
    width: 75px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ disabled, theme: { quinaryColor }  }) => (disabled ? quinaryColor : styles.colors.primary600)};
`
const StyledActionText = styled(ActionText)`
    span {    
        color: ${styles.colors.black300};
        font-size: 12px;
    }

    &:hover{
        path { fill: ${styles.colors.primary400} }
    }

    div {
        margin-right: 4px;
        svg {
            margin-bottom: 4px;
        }
    }
`

export default BulkConnectionActionsDropdown
