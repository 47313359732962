import { useNavigate } from "react-router-dom"
import { ILinkedInUserData, IUser } from "../../../interfaces/models/user"
import { IAuthData } from "../../../interfaces/store/authStore"
import { useAppActions } from "../../../stores/application/useAppActions"
import { useAuthActions } from "../../../stores/auth/useAuthActions"
import { useAuthRequest } from "../../apis/auth/useAuthRequest"
import { useExtensionRequest } from "../../apis/extension/useExtensionRequest"
import { IOnboardingData, useUserRequest } from "../../apis/user/useUserRequest"
import { IWorkspaceCreateParams, useWorkspaceRequest } from "../../apis/workspace/useWorkspaceRequest"
import { AppLiterals, headerRoutes } from "../../../interfaces/store/appStore"
import { useAppStore } from "../../../stores/application/useAppStore"
import { IUserWorkspace } from "../../../interfaces/response/workspace"
import { useAuthStore } from "../../../stores/auth/useAuthStore"
import { useAppHandlers } from "../app/useAppHandlers"
import moment from "moment"
import { useTagsHandlers } from "../tags/useTagsHandlers"
import { useNotesHandlers } from "../notes/useNotesHandlers"
import { useTasksHandlers } from "../tasks/useTasksHandlers"
import { useTemplateHandlers } from "../template/useTemplateHandlers"
import { useBillingHandlers } from "../billing/useBillingHandlers"
import { useWorkspaceHandlers } from "../workspace/useWorkspaceHandlers"
import { useBillingActions } from "../../../stores/billing/useBillingActions"
import { useConnectionsHandlers } from "../connections/useConnectionsHandlers"

export const useAuthHandlers = () => {

    const { logout,
        setAuthData,
        setUser,
        setContext,
        setLifetime,
        setTrial,
        setValidSubscription,
        setWorkspace,
        setWorkspaceOwner,
        setWorkspaceAdmin,
        setSubscription,
        syncAccesToken,
        syncRefreshToken,
        setUserWorkspaces,
        setDisablePremiumFeatures,
        setDisconnectedConnectionsCount,
        setIsLoggedToLinkedin,
        setScheduledRemoval
    } = useAuthActions()
    const { getUser, switchUserWorkspace, generateAPIKey, revokeAPIKey  } = useAuthRequest()
    const { getLoggedInLIUser, putSessionTokens, setUserLoggedAuth, syncConnections, syncConversations, getLDAccessToken, getExtensionStatus } = useExtensionRequest()
    const { setOnboardingStep, setHeaderState } = useAppActions()
    const { createWorkspace } = useWorkspaceRequest()
    const { postOnboardingData, getWorkspaces, saveLinkedInData, saveUserPreferences, saveTagsAndNotesSettings } = useUserRequest()
    const { store: { extensionInstalled } } = useAppStore()
    const { store: { workspace, user, userWorkspaces, authData } } = useAuthStore()
    const navigate = useNavigate()
    const { showInfoToast, showErrorToast, showSpinner, hideSpinner } = useAppHandlers()
    const {resetTagsStoreHandler} = useTagsHandlers();
    const {resetNotesStoreHandler} = useNotesHandlers();
    const {resetTasksStoreHandler} = useTasksHandlers();
    const {resetTemplatesStoreHandler} = useTemplateHandlers();
    const {resetBillingStore} = useBillingActions();
    const {resetWorkspaceUsersStoreHandler} = useWorkspaceHandlers();
    const {resetConnectionsStoreHandler} = useConnectionsHandlers();

    const getMeHandler = (() => {
        //TODO: This can be upgraded if user is impatient or on edge and refresh a page, to check from localstorage if user is synced, if not to run checkUserSynced()
        const checkUserSynced = async () => {
            if (!user?.isSynced) {
                try {
                    const result = await getUser(authData, 'getMe'); // Call getMe to attempt syncing the user
                    
                    // If not synced, retry after 10 seconds
                    if (!result?.user?.isSynced) {
                        setTimeout(checkUserSynced, 10000);
                    } else {
                        console.log('User is synced!');
                        setUser(result?.user)
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                    // Retry even if there's an error
                    setTimeout(checkUserSynced, 10000);
                }
            }
        };
    
        return () => {
            checkUserSynced(); // Start the initial check
        };
    })()
    
    const setAuthDataHandler = async (payload: IAuthData, extensionData?: boolean) => {
        let accessToken = payload?.accessToken ?? localStorage.getItem('at') as any //check this
        syncAccesToken(accessToken)
        let refreshToken = payload?.refreshToken ?? localStorage.getItem('rt') as any 
        syncRefreshToken(refreshToken)

        if (accessToken) {
            setAuthData({ accessToken, refreshToken })
        }

        getUserHandler({ accessToken, refreshToken }, extensionData)

        // add to the extension
        await putSessionTokens({accessToken, refreshToken})
    }

    const getUserHandler = async (payload?: IAuthData, extensionData?: boolean) => {
        let userLI
        let extensionVersion
        if(extensionData || extensionInstalled) {
            userLI = await getLoggedInLIUserHandler()
            const result = await getExtensionStatus()
            extensionVersion = result?.version ?? undefined
        } 
        const result = await getUser(payload)
        setUserHandler(result, extensionData, { authData: payload }, userLI)
    }

    const setUserHandler = async (result: any, extensionData?: boolean, authData?: any, userLI?: ILinkedInUserData) => {
        extensionData = extensionData ? extensionData : extensionInstalled
        setUser(result?.user)
        setContext(result?.context === "personal" || !result?.context ? "individual" : result?.context) //todo: remove personal
        const isLifetime = (result?.hasCouponApplied || result?.user?.plan === "lifetime") && result?.workspace?.isPersonal;
        setLifetime(isLifetime)
        setTrial(result?.isOnTrialPeriod > 0)
        setValidSubscription(result?.isValidSubscription)
        setWorkspace(result?.workspace)
        setDisablePremiumFeatures(!(result?.isOnTrialPeriod > 0) && !result?.isValidSubscription && !isLifetime && !result?.subscription?.pausedByAdmin)
        setWorkspaceOwner(result?.isWorkspaceOwner ?? false)
        setWorkspaceAdmin(result?.isWorkspaceAdmin ?? false)
        const notLoggedInLinkedIn = result?.user?.linkedinData && !userLI
        const connectedLIUser = result?.user?.linkedinData && userLI && userLI?.publicIdentifier === result?.user?.linkedinData?.publicIdentifier
        const onboardingStep = result?.newUser && !result?.workspace ? 0 
        : result?.newUser && result?.workspace && !extensionData ? 1
        : notLoggedInLinkedIn || connectedLIUser || !extensionData ? -2
        : extensionData ? 2 : -1
        setOnboardingStep(onboardingStep)
        setHeaderState(!result || onboardingStep >= 0 ? {} : (
            {
                ...headerRoutes, 
                inbox: {
                    ...headerRoutes['inbox'], 
                    visible: result
                },
                integration: {
                    ...headerRoutes['integrations'],
                    visible: result?.isWorkspaceOwner || result?.isWorkspaceAdmin
                }}
        ))
        if(result) setSubscription(result?.subscription)
        if(result?.user){
            setLastSyncAt(result.user.lastSyncAt ?? 0)
            if(result?.user?.isSynced && extensionData) doAutomaticSync(result?.user)
        }

        if(result?.scheduledRemoval){
            if(result?.scheduledRemoval?.inProgress
                && result?.scheduledRemoval?.total > result?.scheduledRemoval?.count){
                setScheduledRemoval(result?.scheduledRemoval);
            }
        }

        //Set user in extension chrome storage
        await setUserLoggedAuth({
            user: {
                context: 'workspace',
                csrfToken: authData?.accessToken,
                refreshToken: authData?.refreshToken,
                linkedInUserData: {...result?.user?.linkedinData},
                disablePremiumFeatures: !(result?.isOnTrialPeriod > 0) && !result?.isValidSubscription && !isLifetime && !result?.subscription?.pausedByAdmin,
                ...result
            }
        })
    }

    //todo: not to be confused with the regular create that should not set workspace as the currently logged in, the other one needs to go to worspace handlers
    const createAndLoginToWorkspaceHandler = async (payload: IWorkspaceCreateParams) => {
        const result = await createWorkspace(payload, false);
        if(result) {
            const token = (await switchUserWorkspace({
                context: AppLiterals.WORKSPACE,
                workspaceId: result._id,
            }))?.token;
            if (!token) return;
            await setAuthDataHandler({ accessToken: `Bearer ${token}`});
        }
    }

    const changeUserWorkspace = async (newWorkspace: IUserWorkspace) => {
        if (!newWorkspace || newWorkspace.workspaceId === workspace?._id) return; 

        // reset stores
        resetAllStores();
        
        // Get context access token 
        const token = (await switchUserWorkspace({
                context: newWorkspace.workspaceId ? AppLiterals.WORKSPACE : AppLiterals.INDIVIDUAL,
                ...(newWorkspace.workspaceId ? { workspaceId: newWorkspace.workspaceId } : {}),
            }))?.token;
        if (!token) return;

        await setAuthDataHandler({ accessToken: `Bearer ${token}`});
        
        navigate(0);
    }

    const createWorkspaceHandler = async (payload: IWorkspaceCreateParams) => {
        await createWorkspace(payload);
        await getUserWorkspacesHandler();
    }

    const saveOnboardingData = async (payload: IOnboardingData) => {
        const result = await postOnboardingData(payload);
        if(result) {
            let userLI
            if(extensionInstalled) userLI = await getLoggedInLIUserHandler()
            setUserHandler(result, undefined, undefined, userLI)
        }
    }

    const saveLinkedInDataToUser = async (payload: ILinkedInUserData) => {
        const result = await saveLinkedInData({ userLinkedInInfo: payload })
        if(result) setUserHandler(result, undefined, undefined, payload)
    }

    const getLoggedInLIUserHandler = async (): Promise<ILinkedInUserData | undefined> => {
        const result = await getLoggedInLIUser()
        setIsLoggedToLinkedin(result ? true : false)
        return result ?? undefined
    }

    const getUserWorkspacesHandler = async () => {
        const result = await getWorkspaces()
        if(result) {
            setUserWorkspaces([...(result?.workspaces || [])])
            const selectedWorkspace = result?.workspaces.find((userWorkspace: any) => workspace._id === userWorkspace.workspaceId)
            selectedWorkspace && setWorkspace({...workspace, showAutoTags: selectedWorkspace.showAutoTags })
        } 
        
    }

    const doAutomaticSync = async (user: IUser) => {
        const lastItemExist = window.localStorage.getItem('lastSyncAt')
        if(lastItemExist && lastItemExist !== 'undefined'){
            const currentDate = moment();
            const syncDate = moment(lastItemExist);
            if(currentDate > syncDate.add(12, 'hours')) {
                //auto sync
                const updateDone = await doForceUpdateSync(user, syncDate)
                if(!updateDone) syncConnectionsHandler(user);
                console.log('doAutomaticSync syncConversationsHandler');
                syncConversationsHandler();
            }
        }
    }

    //todo: use this every 24 hours
    const doForceUpdateSync = async (user: IUser, lastSyncDate: any) => {
        const lastItemExist = window.localStorage.getItem('lastForcedUpdatedAt')
        if(lastSyncDate || (lastItemExist && lastItemExist !== 'undefined')){
            const currentDate = moment();
            const syncDate = moment(lastItemExist);
            if(currentDate > lastSyncDate.add(120, 'hours') || currentDate > syncDate.add(120, 'hours')) {
                //auto sync
                syncConnectionsHandler(user, true);
                return true
            }
            return false
        }
    }

    const syncConnectionsHandler = async (user: IUser, forceUpdate?: boolean, authData?: IAuthData) => {
        if(authData && authData.accessToken){
            await putSessionTokens({accessToken: authData.accessToken, refreshToken: authData.refreshToken})
            await getLDAccessToken()
        }
        const result = await syncConnections(forceUpdate)
        //do somothing for the first sync
        if(result) {
            setLastSyncAt(new Date().toISOString(), forceUpdate);
            setUser({...user, lastSyncAt: new Date().toISOString()})
            showInfoToast({message: "Connection sync started, connections should appear after the process is done"})
        }
        else showErrorToast({message: "Action failed. Ensure you're logged into LinkedIn and the extension, then try a hard refresh."})
    }

    const syncConversationsHandler = async () => {
        await syncConversations();
    }

    const setLastSyncAt = async (lastSyncAt: string, forceUpdate?: boolean) => {
        const currentValue = window.localStorage.getItem('lastSyncAt');
        const currentValueForceUpdate = window.localStorage.getItem('lastForcedUpdatedAt');
        if(moment(lastSyncAt) > moment(currentValue && currentValue !== 'undefined' ? currentValue : 0)) window.localStorage.setItem('lastSyncAt', lastSyncAt)
        if(forceUpdate && moment(lastSyncAt) > moment(currentValueForceUpdate && currentValueForceUpdate !== 'undefined' ? currentValueForceUpdate : 0)) window.localStorage.setItem('lastForcedUpdatedAt', lastSyncAt)
    }

    const logoutHandler = async (to?: string) => {
        await logout();
        localStorage.removeItem('lastSyncAt')
        setHeaderState(undefined);
        resetAllStores();
        if (to) navigate(to);
    }

    const saveUserPreferencesHandler = async (payload: any) => {

        const result = await saveUserPreferences({preferences: {
            ...user?.preferences,
            ...payload
        }})
        result?.user && setUser(result.user)
    }

    const saveTagsAndNotesSettingsHandler = async (showAutoTags: boolean) => {
        const payload = {
            user: user,
            workspaceId: workspace?._id,
            showAutoTags: showAutoTags

        }
        const result = await saveTagsAndNotesSettings(payload)
        if(result?.success) {
            setUserWorkspaces(userWorkspaces.map((userWorkspace: any) => {
                if(workspace._id === userWorkspace.workspaceId) return { ...userWorkspace, showAutoTags }
                return {...userWorkspace}
            }))
             setWorkspace({...workspace, showAutoTags: showAutoTags })
        }
    }

    const setDisconnectedConnectionsCountHandler = async (count: any) => {
        setDisconnectedConnectionsCount(count);
    }

    const generateAPIKeyHandler = async () => {
        showSpinner()
        try {
            const { apiKey } = await generateAPIKey()
            showInfoToast({message: 'LeadDelta API key generated successfully.'})
            return apiKey
        } catch(error) {
            showErrorToast({message: 'There was a problem generating your LeadDelta API key.'})
        } finally {
            hideSpinner()
        }
    }

    const revokeAPIKeyHandler = async () => {
        showSpinner()
        try {
            await revokeAPIKey()
            showInfoToast({message: 'LeadDelta API key revoked successfully.'})
        } catch(error) {
            showErrorToast({message: 'There was a problem revoking your LeadDelta API key.'})
        } finally {
            hideSpinner()
        }
    }

    const resetAllStores = async () => {
        // reset stores
        resetConnectionsStoreHandler();
        resetTagsStoreHandler();
        resetNotesStoreHandler();
        resetTasksStoreHandler();
        resetTemplatesStoreHandler();
        resetBillingStore(); // can't use billing handler because of circular dependency
        resetWorkspaceUsersStoreHandler();
    }

    return {
        setAuthDataHandler,
        getUserHandler,
        getMeHandler,
        getLoggedInLIUserHandler,
        createAndLoginToWorkspaceHandler,
        createWorkspaceHandler,
        getUserWorkspacesHandler,
        saveOnboardingData,
        changeUserWorkspace,
        syncConnectionsHandler,
        saveLinkedInDataToUser,
        logoutHandler,
        saveUserPreferencesHandler,
        saveTagsAndNotesSettingsHandler,
        doAutomaticSync,
        doForceUpdateSync,
        syncConversationsHandler,
        setDisconnectedConnectionsCountHandler,
        generateAPIKeyHandler,
        revokeAPIKeyHandler
    }
}